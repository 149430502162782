import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AssetContext } from '../contexts/AssetListContext.js';
import backIcon from '../assets/icons/navigate_before.svg';
import graphics from '../assets/icons/graphics.svg';
import filterIcon from '../assets/icons/down_arrow.svg';

const RemedialActions = () => {
    const { defects } = useContext(AssetContext);
    const navigate = useNavigate();
    const [colors, setColors] = useState({});
    const [filters, setFilters] = useState({})
    const [filteredDefects, setFilteredDefects] = useState(defects);
    const [activeFilter, setActiveFilter] = useState(null);
    
    const getUniqueValues = (field) => {
        const relevantFilters = Object.fromEntries(
            Object.entries(filters).filter(([key]) => key !== field)
        );

        const filteredForField = defects.filter(defect => 
            Object.entries(relevantFilters).every(([key, values]) => {
                if (!values || values.length === 0) return true;
                const defectValue = String(defect[key] || '').toLowerCase();
                return values.some(value => 
                    String(value).toLowerCase() === defectValue
                );
            })
        );

        return [...new Set(filteredForField.map(defect => defect[field]))].filter(Boolean);
    };
    
    // Change the names if risk rate name changes
    useEffect(() => {
        const riskColors = {
            'Very High': getCssVariableValue('--risk-color-4'),
            'High': getCssVariableValue('--risk-color-3'),
            'Medium': getCssVariableValue('--risk-color-2'),
            'Low': getCssVariableValue('--risk-color-1'),
        };
        setColors(riskColors);
    }, []);

    useEffect(() => {
        filterData();
    }, [filters, defects]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest('.filter-dropdown') && !event.target.closest('.filter-icon')) {
                setActiveFilter(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const getCssVariableValue = (variableName) => {
        return getComputedStyle(document.documentElement).getPropertyValue(variableName).trim();
    };

    const goBack = () => {
        navigate('/analytics');
    };

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-AU', {
            style: 'currency',
            currency: 'AUD'
        }).format(value);
    };

    const handleFilterClick = (field) => {
        setActiveFilter(activeFilter === field ? null : field);
    };

    const handleFilterSelect = (field, value) => {
        setFilters(prev => {
            const currentValues = prev[field] || [];
            const valueIndex = currentValues.indexOf(value);
            
            let newValues;
            if (valueIndex === -1) {
                // Add the value if not present
                newValues = [...currentValues, value];
            } else {
                // Remove the value if it is already present
                newValues = currentValues.filter((_, index) => index !== valueIndex);
            }
            
            return {
                ...prev,
                [field]: newValues
            };
        });
    };

    const clearFilter = (field) => {
        setFilters(prev => {
            const newFilters = { ...prev };
            delete newFilters[field];
            return newFilters;
        });
    };

    const filterData = () => {
        const filtered = defects.filter(defect => {
            return Object.entries(filters).every(([key, values]) => {
                if (!values || values.length === 0) return true;
                const defectValue = String(defect[key] || '').toLowerCase();
                return values.some(value => 
                    String(value).toLowerCase() === defectValue
                );
            });
        });
        setFilteredDefects(filtered);
    };

    const FilterDropdown = ({ field }) => {
        const options = getUniqueValues(field);
        const selectedValues = filters[field] || [];
        
        return (
            <div className="">
                <button
                    className='filter-button'
                    onClick={() => handleFilterClick(field)}
                >
                    <img 
                        src={filterIcon} 
                        alt="Filter"    
                    />
                    {selectedValues.length > 0 && (
                        <span className="filter-count">{selectedValues.length}</span>
                    )}
                </button>
                
                {activeFilter === field && (
                    <div className="filter-dropdown">
                        <div className="select-options-container">
                            <div className='select-options'>
                                <button
                                    className="clear-filter-button"
                                    onClick={() => clearFilter(field)}
                                >
                                    Clear filter
                                </button>
                                {options.map((option, index) => (
                                    <button
                                        key={index}
                                        className={`filter-option ${selectedValues.includes(option) ? 'selected-option' : ''}`}
                                        onClick={() => handleFilterSelect(field, option)}
                                    >
                                        <span className="option-text">{option}</span>
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    const renderHeader = (field, label, showFilter = true) => (
        <th>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>{label}</span>
                {showFilter && <FilterDropdown field={field} />}
            </div>
        </th>
    );

    return (
        <div className='cost-analysis-container'>
            <div className='cost-analytics-title'>
                <div>
                    <h2>Analytics</h2>
                    <Link to="/cost-analysis" className='analysis-button'>
                        Cost Analysis
                    </Link>
                    <Link to="" className='analysis-button-active'>
                        Remedial Actions
                    </Link>
                </div>
                <div style={{ display: 'flex', alignContent: 'center' }}>
                    <Link to="/graphics" className='graphics-button'>
                        <img src={graphics} className="button-image" alt="Graphics" />
                        <div style={{ display: 'block' }}>
                            <h6 style={{ fontSize: '16px' }}>Action Tasks</h6>
                            
                        </div>
                    </Link>
                    <button className='back-button' style={{ width: '100px' }} onClick={goBack}>
                        <img src={backIcon} alt="Back" />
                        Analytics
                    </button>
                </div>
            </div>

            <table>
                <thead>
                    <tr>
                        {renderHeader('asset_name', 'Asset')}
                        {renderHeader('defect_id', 'Defect', false)}
                        {renderHeader('risk_rate', 'Risk Level')}
                        {renderHeader('remedial action description', 'Remedial action description', false)}
                        {renderHeader('Task type', 'Task Type')}
                        {renderHeader('Cost ($ AUD)', 'Cost', false)}
                        {renderHeader('remediation timeframe', 'Remediation timeframe', false)}
                    </tr>
                </thead>
                <tbody>
                    {filteredDefects.map((defect, index) => (
                        <tr key={index}>
                            <td>{defect.asset_name}</td>
                            <td
                                onClick={() => navigate(`/defect-details/${defect.defect_id}`)}
                                style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                            >  
                                {defect.defect_id}  
                            </td>
                            <td style={{ display: 'flex', alignItems: 'center', gap: '6px'}}>
                                <span
                                    style={{ backgroundColor: colors[defect.risk_rate], height: '15px', width: '15px', borderRadius: '4px', }}
                                />
                                {defect.risk_rate}
                            </td>
                            <td>{defect['remedial action description']}</td>
                            <td>{defect['Task type']}</td>
                            <td>{formatCurrency(defect['Cost ($ AUD)'])}</td>
                            <td>{defect['remediation timeframe']}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default RemedialActions;