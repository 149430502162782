import React from 'react';

// function to show alert pupop
function CustomAlert({ message, type, onClose }) {
    return (
        <div className={`alert ${type}`}>
            <span>{message}</span>
            <button onClick={onClose}>X</button>
        </div>
    );
}

export default CustomAlert;