import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { AssetContext } from '../contexts/AssetListContext.js';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip as BarTooltip, Legend } from 'recharts';
import backIcon from '../assets/icons/navigate_before.svg'

const CostAnalysis = () => {
    const { defects } = useContext(AssetContext);
    const navigate = useNavigate();
    const [colors, setColors] = useState({});
    const [selectedRiskRatesForPieChart, setSelectedRiskRatesForPieChart] = useState([]);
    const [selectedRiskRatesForAsset, setSelectedRiskRatesForAsset] = useState(['High']); // Modified to array
    const [selectedRiskRatesForDefect, setSelectedRiskRatesForDefect] = useState(['High']); // Modified to array
    const [filteredData, setFilteredData] = useState([]);
    const [filteredDataByDefectType, setFilteredDataByDefectType] = useState([]);
    const [dataPie, setDataPie] = useState([]);

    useEffect(() => {
        const riskColors = {
            'Very High': getCssVariableValue('--risk-color-4'),
            'High': getCssVariableValue('--risk-color-3'),
            'Medium': getCssVariableValue('--risk-color-2'),
            'Low': getCssVariableValue('--risk-color-1'),
        };
        setColors(riskColors);
    }, []);

    const getCssVariableValue = (variableName) => {
        return getComputedStyle(document.documentElement).getPropertyValue(variableName).trim();
    };

    // Modified filter for asset costs with multiple risk rates
    useEffect(() => {
        if (selectedRiskRatesForAsset.length > 0) {
            const filtered = defects
                .filter(defect => selectedRiskRatesForAsset.includes(defect.risk_rate))
                .reduce((acc, defect) => {
                    const { asset_name, ['Cost ($ AUD)']: cost, risk_rate } = defect;
                    if (!acc[asset_name]) {
                        acc[asset_name] = {};
                    }
                    if (!acc[asset_name][risk_rate]) {
                        acc[asset_name][risk_rate] = 0;
                    }
                    acc[asset_name][risk_rate] += parseFloat(cost);
                    return acc;
                }, {});

            const dataBar = Object.entries(filtered).map(([name, costs]) => ({
                name,
                ...costs,
            }));

            setFilteredData(dataBar);
        }
    }, [selectedRiskRatesForAsset, defects]);

    // Modified filter for defect type costs with multiple risk rates
    useEffect(() => {
        if (selectedRiskRatesForDefect.length > 0) {
            const filteredByDefectType = defects
                .filter(defect => selectedRiskRatesForDefect.includes(defect.risk_rate))
                .reduce((acc, defect) => {
                    const { defect_type, ['Cost ($ AUD)']: cost, risk_rate } = defect;
                    if (!acc[defect_type]) {
                        acc[defect_type] = {};
                    }
                    if (!acc[defect_type][risk_rate]) {
                        acc[defect_type][risk_rate] = 0;
                    }
                    acc[defect_type][risk_rate] += parseFloat(cost);
                    return acc;
                }, {});

            const dataBarDefectType = Object.entries(filteredByDefectType).map(([name, costs]) => ({
                name,
                ...costs,
            }));

            setFilteredDataByDefectType(dataBarDefectType);
        }
    }, [selectedRiskRatesForDefect, defects]);

    useEffect(() => {
        const filteredDefects = selectedRiskRatesForPieChart.length > 0
            ? defects.filter(defect => selectedRiskRatesForPieChart.includes(defect.risk_rate))
            : defects;

        const riskCosts = filteredDefects.reduce((acc, defect) => {
            const { risk_rate, ['Cost ($ AUD)']: cost } = defect;
            if (!acc[risk_rate]) {
                acc[risk_rate] = 0;
            }
            acc[risk_rate] += parseFloat(cost);
            return acc;
        }, {});

        const newDataPie = Object.entries(riskCosts).map(([risk_rate, totalCost]) => ({
            name: risk_rate,
            value: totalCost,
        }));

        setDataPie(newDataPie);
    }, [selectedRiskRatesForPieChart, defects]);

    const riskRatesOrder = ['Very High', 'High', 'Medium', 'Low'];

    const riskRates = [...new Set(defects.map(defect => defect.risk_rate))]
        .sort((a, b) => riskRatesOrder.indexOf(a) - riskRatesOrder.indexOf(b));

    // Modified handlers for multiple selection
    const handleRiskRateChangeForAsset = (rate) => {
        setSelectedRiskRatesForAsset(prevSelected => {
            if (prevSelected.includes(rate)) {
                return prevSelected.filter(r => r !== rate);
            } else {
                return [...prevSelected, rate];
            }
        });
    };

    const handleRiskRateChangeForDefect = (rate) => {
        setSelectedRiskRatesForDefect(prevSelected => {
            if (prevSelected.includes(rate)) {
                return prevSelected.filter(r => r !== rate);
            } else {
                return [...prevSelected, rate];
            }
        });
    };

    const handleRiskRateChangeForPieChart = (rate) => {
        setSelectedRiskRatesForPieChart(prevSelected => {
            if (prevSelected.includes(rate)) {
                return prevSelected.filter(r => r !== rate);
            } else {
                return [...prevSelected, rate];
            }
        });
    };

    const taskTypeCosts = defects.reduce((acc, defect) => {
        const { risk_rate, ['Task type']: task_type, ['Cost ($ AUD)']: cost } = defect;

        if (!acc[risk_rate]) {
            acc[risk_rate] = { Corrective: 0, Preventive: 0 };
        }

        if (task_type === 'Corrective') {
            acc[risk_rate].Corrective += parseFloat(cost);
        } else if (task_type === 'Preventive') {
            acc[risk_rate].Preventive += parseFloat(cost);
        }

        return acc;
    }, {});

    const dataBarRisk = Object.entries(taskTypeCosts).map(([risk_rate, costs]) => ({
        name: risk_rate,
        Corrective: costs.Corrective,
        Preventive: costs.Preventive,
    }));

    // Order dataBarRisk according to the order of riskRatesOrder
    const sortedDataBarRisk = dataBarRisk.sort((a, b) => {
        return riskRatesOrder.indexOf(a.name) - riskRatesOrder.indexOf(b.name);
    });

    const CustomLegend = (props) => {
        const { payload } = props;
        return (
            <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', position: 'absolute', bottom: '-25px', right: '90px' }}>
                {payload.map((entry, index) => (
                    <span key={`item-${index}`} style={{ color: entry.color }}>
                        {entry.value}
                    </span>
                ))}
            </div>
        );
    };

    const CustomXAxisTick = ({ x, y, payload }) => {
        return (
            <g transform={`translate(${x},${y})`}>
                <text x={-15} y={10} textAnchor="middle" fill="#A1A1A1" fontSize={12}>
                    C
                </text>
                <text x={15} y={10} textAnchor="middle" fill="#A1A1A1" fontSize={12}>
                    P
                </text>
                <text x={0} y={25} textAnchor="middle" fill="#000" fontSize={14}>
                    {payload.value}
                </text>
            </g>
        );
    };

    // Sort the data by total cost from lowest to highest
    const sortedData = [...filteredData].sort((a, b) => {
        const totalA = selectedRiskRatesForAsset.reduce((sum, rate) => sum + (a[rate] || 0), 0);
        const totalB = selectedRiskRatesForAsset.reduce((sum, rate) => sum + (b[rate] || 0), 0);
        return -totalA + totalB;
    });

    // Sort the data by total cost from lowest to highest
    const sortedDataByDefectType = [...filteredDataByDefectType].sort((a, b) => {
        const totalA = selectedRiskRatesForDefect.reduce((sum, rate) => sum + (a[rate] || 0), 0);
        const totalB = selectedRiskRatesForDefect.reduce((sum, rate) => sum + (b[rate] || 0), 0);
        return -totalA + totalB;
    });

    const goBack = () => {
        navigate('/analytics');
    };

    return (
        <div className='cost-analysis-container'>
            <div className='cost-analytics-title'>
                <div>
                    <h2>Analytics</h2>
                    <Link to=""
                        className='analysis-button-active'
                    >
                        Cost Analysis
                    </Link>
                    <Link to="/remedial-actions"
                        className='analysis-button'
                    >
                        Remedial Actions
                    </Link>
                </div>
                <div>
                    {/* Section to change risk rate name  */}
                    <div style={{ display: 'block', position: 'relative'}}>
                    <div className='pie-chart-legend'>
                        <div className='analytics-risk-rate-section'>
                            <div style={{ display: 'flex', gap: '10px' }}><div className='risk-square risk-color-4'></div><p>Very High</p></div>
                            <div style={{ display: 'flex', gap: '10px' }}><div className='risk-square risk-color-3'></div><p>High</p></div>
                            <div style={{ display: 'flex', gap: '10px' }}><div className='risk-square risk-color-2'></div><p>Medium</p></div>
                            <div style={{ display: 'flex', gap: '10px' }}><div className='risk-square risk-color-1'></div><p>Low</p></div>
                        </div>
                    </div>
                    <span style={{ position: 'absolute', right: '35%', fontSize: '14px', color: '#333'}}>All values in AUD</span>
                    </div>
                    {/* ------------------------------------------- */}
                    <button
                        className='back-button'
                        style={{ width: '100px' }}
                        onClick={goBack}
                    >
                        <img src={backIcon} alt="Back" />
                        Analytics
                    </button>
                </div>
            </div>

            <div className='cost-analysis-charts'>
                <div className='grid-container'>
                    <div className='grid-item'>
                        <h3>Cost by Risk</h3>
                        <PieChart width={420} height={260}>
                            <Pie
                                data={dataPie}
                                dataKey="value"
                                cx="50%"
                                cy="50%"
                                innerRadius={60}
                                outerRadius={100}
                                label={(entry) => `$${entry.value.toLocaleString()}`}
                                tick={{ fontSize: 8 }}
                            >
                                {dataPie.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={colors[entry.name]} />
                                ))}
                            </Pie>
                            <Tooltip
                                formatter={(value, name) => [`$${value.toLocaleString()}`, name]}
                                contentStyle={{
                                    borderRadius: '4px',
                                    color: '#333',
                                    fontSize: '14px',
                                    padding: '10px'
                                }}
                            />
                        </PieChart>
                        <div className="risk-rate-filter-checkboxes">
                            {riskRates.map(rate => (
                                <label key={rate} style={{ marginRight: '10px', alignContent: 'center' }}>
                                    <input
                                        type="checkbox"
                                        style={{ marginRight: '5px' }}
                                        checked={selectedRiskRatesForPieChart.includes(rate)}
                                        onChange={() => handleRiskRateChangeForPieChart(rate)}
                                    />
                                    {rate}
                                </label>
                            ))}
                        </div>
                    </div>

                    <div className='grid-item'>
                        <h3>Cost by Risk and Task and Task Type</h3>
                        <BarChart width={460} height={240} data={sortedDataBarRisk}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <YAxis
                                type="number"
                                tickFormatter={(value) => `$${value.toLocaleString()}`}
                                tick={{ fontSize: 12 }}
                            />
                            <XAxis
                                tick={<CustomXAxisTick />}
                                dataKey="name"
                            />
                            <BarTooltip
                                formatter={(value, name) => [`$${value.toLocaleString()}`, name]}
                                contentStyle={{
                                    borderRadius: '4px',
                                    color: '#000',
                                    fontSize: '14px',
                                    padding: '10px'
                                }}
                            />
                            <Bar dataKey="Corrective" name="Corrective (C)">
                                {dataBarRisk.map((entry, index) => (
                                    <Cell key={`cell-corrective-${index}`} fill={colors[entry.name]} />
                                ))}
                            </Bar>
                            <Bar dataKey="Preventive" name="Preventive (P)">
                                {dataBarRisk.map((entry, index) => (
                                    <Cell key={`cell-preventive-${index}`} fill={colors[entry.name]} />
                                ))}
                            </Bar>
                            <Legend content={<CustomLegend />} />
                        </BarChart>
                    </div>

                    <div className='grid-item'>
                        <h3>Cost by Asset (Filtered by Risk Rate)</h3>
                        {filteredData.length > 0 ? (
                            <div style={{ height: '240px', overflowY: 'auto' }}>
                                <BarChart width={480} height={filteredData.length * 40} data={sortedData} layout="vertical">
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis
                                        type="number"
                                        tickFormatter={(value) => `$${value.toLocaleString()}`}
                                        tick={{ fontSize: 12 }}
                                    />
                                    <YAxis
                                        dataKey="name"
                                        type="category"
                                        width={120}
                                        tick={{ fontSize: 12 }}
                                    />
                                    <BarTooltip
                                        formatter={(value, name) => [`$${value.toLocaleString()}`, name]}
                                        contentStyle={{
                                            borderRadius: '4px',
                                            fontSize: '14px',
                                            padding: '10px'
                                        }}
                                        itemStyle={{ color: '#000' }}
                                    />
                                    {selectedRiskRatesForAsset.map(riskRate => (
                                        <Bar
                                            key={riskRate}
                                            dataKey={riskRate}
                                            stackId="stack"
                                            fill={colors[riskRate]}
                                        />
                                    ))}
                                </BarChart>
                            </div>
                        ) : (
                            <p>No data available for selected risk rate.</p>
                        )}
                        <div className="risk-rate-filter-checkboxes">
                            {riskRates.map(rate => (
                                <label key={rate} style={{ marginRight: '10px', alignContent: 'center' }}>
                                    <input
                                        type="checkbox"
                                        style={{ marginRight: '5px' }}
                                        checked={selectedRiskRatesForAsset.includes(rate)}
                                        onChange={() => handleRiskRateChangeForAsset(rate)}
                                    />
                                    {rate}
                                </label>
                            ))}
                        </div>
                    </div>

                    {/* Bar chart - Defect Type */}
                    <div className='grid-item'>
                        <h3>Cost by Defect Type (Filtered by Risk Rate)</h3>
                        {filteredDataByDefectType.length > 0 ? (
                            <div style={{ height: '240px', overflowY: 'auto' }}>
                                <BarChart width={480} height={240} data={sortedDataByDefectType} layout="vertical">
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis
                                        type="number"
                                        tickFormatter={(value) => `$${value.toLocaleString()}`}
                                        tick={{ fontSize: 12 }}
                                    />
                                    <YAxis
                                        dataKey="name"
                                        type="category"
                                        width={100}
                                        tick={{ fontSize: 12 }}
                                    />
                                    <BarTooltip
                                        formatter={(value, name) => [`$${value.toLocaleString()}`, name]}
                                        contentStyle={{
                                            borderRadius: '4px',
                                            fontSize: '14px',
                                            padding: '10px'
                                        }}
                                        itemStyle={{ color: '#000' }}
                                    />
                                    {selectedRiskRatesForDefect.map(riskRate => (
                                        <Bar
                                            key={riskRate}
                                            dataKey={riskRate}
                                            stackId="stack"
                                            fill={colors[riskRate]}
                                        />
                                    ))}
                                </BarChart>
                            </div>
                        ) : (
                            <p>No data available for selected risk rate.</p>
                        )}
                        <div className="risk-rate-filter-checkboxes">
                            {riskRates.map(rate => (
                                <label key={rate} style={{ marginRight: '10px', alignContent: 'center' }}>
                                    <input
                                        type="checkbox"
                                        style={{ marginRight: '5px' }}
                                        checked={selectedRiskRatesForDefect.includes(rate)}
                                        onChange={() => handleRiskRateChangeForDefect(rate)}
                                    />
                                    {rate}
                                </label>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default CostAnalysis;