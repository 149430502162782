import React from 'react';

import alertNotification from '../assets/icons/alert_notification.svg';

function ExtremeAlert({ message = 'Alert Notification', subMessage, onClose }) {
    return (
        <div
            style={{
                backgroundColor: '#5c5c5c',
                color: '#ffffff',
                padding: '15px',
                borderRadius: '10px',
                marginBottom: '15px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                position: 'fixed',
                bottom: '20px',
                right: '20px',
                zIndex: 1000,
                width: '448px',
            }}
        >
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                    src={alertNotification}
                    alt="Alert icon"
                    style={{ width: '40px', height: '40px', marginRight: '30px' }}
                />
                <div>
                    <h5 style={{ margin: 0, fontWeight: '500' }}>{message}</h5>
                    <p style={{ margin: 0 }}>{subMessage}</p>
                </div>
            </div>
            <button
                onClick={onClose}
                style={{
                    backgroundColor: 'transparent',
                    border: 'none',
                    padding: '18px',
                    color: '#ffffff',
                    cursor: 'pointer',
                    fontWeight: 'bold',
                    position: 'absolute', 
                    top: '0px', 
                    right: '0px',
                }}
            >
                X
            </button>
        </div>
    );
}

export default ExtremeAlert;
