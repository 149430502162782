import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'mapbox-gl/dist/mapbox-gl.css';
import analyticsIcon from '../assets/icons/analytics.png';
import digitalIcon from '../assets/icons/digital_twin.png';
import backIcon from '../assets/icons/navigate_before.svg'
import mapboxgl from 'mapbox-gl';
import { AssetContext } from '../contexts/AssetListContext.js';
import { RISK_LEVELS } from '../components/RiskRateSection.js'

const MAPBOX_TOKEN = 'pk.eyJ1IjoiYWxlam9kdWFydGUyMyIsImEiOiJja2wxMm1tMHUwYWdwMnd0NGI0emZrYTF5In0.2fceQ8K9H7KwH7tCSz_tWA';

const Analytics = () => {
  const { defects } = useContext(AssetContext);
  const navigate = useNavigate();
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [isAnalyticsDisabled, setIsAnalyticsDisabled] = useState(true);

  const circleColorExpression = [
    'match',
    ['get', 'risk_rate'],
    'Very High', RISK_LEVELS['Very High'].color,
    'High', RISK_LEVELS['High'].color,
    'Medium', RISK_LEVELS['Medium'].color,
    'Low', RISK_LEVELS['Low'].color,
    '#CCCCCC', // Default color if no risk level matches
  ];

  const toggleSwitch = () => {
    if (mapRef.current && mapLoaded) {
      const newIsEnabled = !isEnabled;
      setIsEnabled(newIsEnabled);

      // Delete existing layers and sources
      if (mapRef.current.getLayer('unclustered-point')) {
        mapRef.current.removeLayer('unclustered-point');
      }
      if (mapRef.current.getLayer('clusters')) {
        mapRef.current.removeLayer('clusters');
      }
      if (mapRef.current.getLayer('cluster-count')) {
        mapRef.current.removeLayer('cluster-count');
      }
      if (mapRef.current.getSource('points')) {
        mapRef.current.removeSource('points');
      }

      // Create the new source with or without clustering
      const geojsonData = {
        type: 'FeatureCollection',
        features: defects.map((defect) => ({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [defect.lon, defect.lat],
          },
          properties: {
            defect_name: defect.defect_name,
            defect_id: defect.defect_id,
            risk_rate: defect.risk_rate,
          },
        })),
      };

      // Add the new source
      mapRef.current.addSource('points', {
        type: 'geojson',
        data: geojsonData,
        cluster: newIsEnabled,
        clusterMaxZoom: 22,
        clusterRadius: 50
      });

      if (newIsEnabled) {
        // Add a layer for all points when clustering is enabled
        mapRef.current.addLayer({
          id: 'unclustered-point',
          type: 'circle',
          source: 'points',
          filter: ['!', ['has', 'point_count']],
          paint: {
            'circle-color': 'rgba(140, 18, 181, 0.4)',
            'circle-radius': 5,
            'circle-stroke-width': 2,
            'circle-stroke-color': '#8c12b5'
          }
        });

        // Add cluster layer
        mapRef.current.addLayer({
          id: 'clusters',
          type: 'circle',
          source: 'points',
          filter: ['has', 'point_count'],
          paint: {
            'circle-color': 'rgba(140, 18, 181, 0.4)',
            'circle-radius': [
              'interpolate',
              ['linear'],
              ['get', 'point_count'],
              1, 5,
              3, 15,
              6, 30,
              7, 40,
              8, 50

            ],
            'circle-stroke-width': 2,
            'circle-stroke-color': '#8c12b5'
          }
        });
      } else {
        // Add risk-rate colored points when clustering is disabled
        mapRef.current.addLayer({
          id: 'unclustered-point',
          type: 'circle',
          source: 'points',
          filter: ['!', ['has', 'point_count']],
          paint: {
            'circle-color': circleColorExpression,
            'circle-radius': 5,
          }
        });
      }
    }
  };

  useEffect(() => {
    if (defects.length > 0 && !mapRef.current) {
      mapboxgl.accessToken = MAPBOX_TOKEN;
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: 'mapbox://styles/mapbox/satellite-streets-v11',
        center: [defects[0].lon, defects[0].lat],
        //center: ["148.368295", "-21.490181"],
        zoom: 22,
        minZoom: 10,
        maxZoom: 22,
        attributionControl: false,
        dragRotate: false,
      });

      mapRef.current.on('load', () => {
        setMapLoaded(true);

        // Initial map setup
        const geojsonData = {
          type: 'FeatureCollection',
          features: defects.map((defect) => ({
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [defect.lon, defect.lat],
            },
            properties: {
              defect_name: defect.defect_name,
              defect_id: defect.defect_id,
              risk_rate: defect.risk_rate,
            },
          })),
        };

        mapRef.current.addSource('points', {
          type: 'geojson',
          data: geojsonData,
          cluster: false
        });

        mapRef.current.addLayer({
          id: 'unclustered-point',
          type: 'circle',
          source: 'points',
          filter: ['!', ['has', 'point_count']],
          paint: {
            'circle-color': circleColorExpression,
            'circle-radius': 5,
          }
        });
      });

      const bounds = defects.reduce((bounds, defect) => {
        return bounds.extend([defect.lon, defect.lat]);
      }, new mapboxgl.LngLatBounds([defects[0].lon, defects[0].lat], [defects[0].lon, defects[0].lat]));

      mapRef.current.fitBounds(bounds, {
        padding: 100,
        maxZoom: 50,
        duration: 1000,
      });
    }
  }, [defects]);

  // Back button
  const goBack = () => {
    navigate('/landing');
  };

  return (
    <div className='map-container'>
      <div className='landing-container'>
        <div className='first-group'>
          <h1>Hail Creek Mine</h1>
          <h5 style={{ color: '#FFF' }}>{isEnabled ? 'Defect Density' : 'Defect Distribution by Risk Rate'}</h5>
        </div>
        <div className='second-group'>
          <button
            className='back-button'
            style={{ pointerEvents: 'auto' }}
            onClick={goBack}
          >
            <img src={backIcon} />
            Coal Main AIM
          </button>
          <Link to="/in-construction" className="link-button">
            <img src={digitalIcon} alt="User Icon" className="button-image"></img>
            <div>
              <h6>Digital Twin</h6>
              <span className="button-text">Description for this part</span>
            </div>
          </Link>
          <Link
            to="/in-construction"
            className={`link-button ${isAnalyticsDisabled ? 'disabled-button' : ''}`}
            style={isAnalyticsDisabled ? { pointerEvents: 'none', backgroundColor: '#C0C0C0', color: '#333', } : {}}
          >
            <img src={analyticsIcon} className="button-image"></img>
            <div>
              <h6>Analytics</h6>
              <span className="button-text" style={{ color: '#5C5C5C' }}>Description for this part</span>
            </div>
          </Link>
          <Link to="/cost-analysis" className='report-button'>
            Cost Analysis
          </Link>
          <Link
            to="/remedial-actions"
            className='report-button'
          >
            Remedial Actions
          </Link>
        </div>
        <div className='analytics-switch'>
          <label className="switch">
            <input type="checkbox" checked={isEnabled} onChange={toggleSwitch} />
            <span className="slider round"></span>
          </label>
        </div>
        {/* Section to change risk rate name  */}
        <div className='analytics-risk-rate' style={{ display: isEnabled ? 'none' : 'flex' }}>
          <h6>Risk Rate</h6>
          <div className='analytics-risk-rate-section'>
            <div style={{ display: 'flex', gap: '10px' }}><div className='risk-square risk-color-4'></div><p>Very High</p></div>
            <div style={{ display: 'flex', gap: '10px' }}><div className='risk-square risk-color-3'></div><p>High</p></div>
            <div style={{ display: 'flex', gap: '10px' }}><div className='risk-square risk-color-2'></div><p>Medium</p></div>
            <div style={{ display: 'flex', gap: '10px' }}><div className='risk-square risk-color-1'></div><p>Low</p></div>
          </div>
        </div>
        {/* ------------------------------------------- */}
      </div>
      <div ref={mapContainerRef} style={{ height: '89.9vh', width: '100%' }} />
    </div>
  );
};

export default Analytics;