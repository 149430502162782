import React, { useState, useEffect } from 'react';

const ChevronDown = () => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width="20" 
    height="20" 
    viewBox="0 0 24 24" 
    fill="none" 
    stroke="#A1A1A1" 
    strokeWidth="2" 
    strokeLinecap="round" 
    strokeLinejoin="round"
  >
    <polyline points="6 9 12 15 18 9"></polyline>
  </svg>
);

const DefectListSelect = ({ options, value, onChange, placeholder }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(value || '');

  useEffect(() => {
    setSelectedOption(value || '');
  }, [value]);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    onChange(option);
  };

  return (
    <div style={{ position: 'relative', width: '100%', display: 'inline-block' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          padding: '12px',
          backgroundColor: 'white',
          border: '1px solid #d1d5db',
          borderRadius: '0.375rem',
          cursor: 'pointer',
          position: 'relative',
        }}
        onClick={toggleDropdown}
      >
        <span style={{ color: selectedOption ? '#A1A1A1' : '#A1A1A1', fontFamily: 'Prometo Trial', }}>
          {selectedOption || placeholder}
        </span>
        <ChevronDown />
      </div>
      {isOpen && (
        <ul
          style={{
            position: 'absolute', 
            zIndex: 10,
            width: '100%',
            marginTop: '4px',
            backgroundColor: 'white',
            border: '1px solid #d1d5db',
            borderRadius: '0.375rem',
            boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
          }}
        >
          {options.map((option, index) => (
            <li
              key={index}
              style={{
                fontFamily: 'Prometo Trial',
                color: '#A1A1A1',
                borderRadius: '10px',
                padding: '8px 12px',
                cursor: 'pointer',
                backgroundColor: 'white',
                border: 'none',
              }}
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default DefectListSelect;
