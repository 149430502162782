import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx'; // Library to parse Excel files
import { useNavigate } from 'react-router-dom';
import backIcon from '../assets/icons/navigate_before.svg';

function DigitalTwin() {
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [iframeLink, setIframeLink] = useState('');
  const [headerText, setHeaderText] = useState('Select a Link'); // State for dynamic header text
  const navigate = useNavigate();

  useEffect(() => {
    // Load the Excel file and read dropdown options
    const loadExcelFile = async () => {
      try {
        const response = await fetch('/data/point_clouds.xlsx'); // Update the path to your Excel file
        const data = await response.arrayBuffer();
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(sheet);
        console.log(parsedData);

        // Assume the Excel file has columns 'label' and 'link'
        const options = parsedData.map(row => ({
          label: row.label,
          link: row.link,
        }));
        setDropdownOptions(options);
      } catch (error) {
        console.error('Error loading Excel file:', error);
      }
    };

    loadExcelFile();
  }, []);

  const handleDropdownChange = (event) => {
    const selectedOption = dropdownOptions.find(
      option => option.label === event.target.value
    );
    if (selectedOption) {
      setIframeLink(selectedOption.link);
      setHeaderText(selectedOption.label); // Update header text dynamically
    } else {
      setHeaderText('Select a Link'); // Reset header text if no option is selected
    }
  };

  const goBack = () => {
    navigate('/landing');
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {/* Top Row: Back Button, Title, Dropdown */}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '10px 20px',
          backgroundColor: '#f5f5f5',
          borderBottom: '1px solid #ccc',
        }}
      >
        <button
          className="back-button"
          onClick={goBack}
          style={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'transparent',
            border: 'none',
            cursor: 'pointer',
            padding: '10px',
            fontSize: '16px',
          }}
        >
          <img
            src={backIcon} // Replace with the correct path to your back icon
            alt="Back"
            style={{ marginRight: '8px', height: '20px' }}
          />
          Back
        </button>
        <h1 style={{ margin: 0, fontSize: '20px', textAlign: 'center' }}>{headerText}</h1>
        <a
          href="https://www.mincka-shm.com/sensors"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            marginLeft: '20px',
            fontSize: '20px',
            textAlign: 'center',
            marginTop: 0,
            color: '#007bff',
            textDecoration: 'none',
          }}
        >
          SHM platform
        </a>
        <select
          className="dropdown-menu"
          onChange={handleDropdownChange}
        >
          <option value="">Select an option</option>
          {dropdownOptions.map((option, index) => (
            <option key={index} value={option.label}>
              {option.label}
            </option>
          ))}
        </select>
      </div>

      {/* Bottom Row: Iframe */}
      <div style={{ flexGrow: 1, padding: '20px', height: '87vh' }}>
        {iframeLink ? (
          <iframe
            src={iframeLink}
            style={{ width: '100%', height: '100%', border: 'none' }}
            title="Dynamic Content"
          />
        ) : (
          <p style={{ textAlign: 'center', color: '#777' }}>Please select an option to load content.</p>
        )}
      </div>
    </div>
  );
}

export default DigitalTwin;