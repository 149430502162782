import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'mapbox-gl/dist/mapbox-gl.css';
import CustomDropdown from '../components/Dropdown.js';
import analyticsIcon from '../assets/icons/analytics.png';
import digitalIcon from '../assets/icons/digital_twin.png';
import customMarkerIcon from '../assets/icons/navigation.png';
import mapboxgl from 'mapbox-gl';
import { AssetContext } from '../contexts/AssetListContext.js';

const MAPBOX_TOKEN = 'pk.eyJ1Ijoibmlrb2FuZHJlIiwiYSI6ImNtODU3MXJqcjAwcGkya3B1N3F6bzBiamoifQ.z8RU8-lQD7c0EBUGdfM_0g';

const customPopupStyle = `
  .mapboxgl-popup-content {
    font-family: 'Prometo Trial',
    background-color: #EBEBEB;
    color: #333333;
    padding: 12px;
    width: 179px;
    height: 66px;
    border-radius: 4px;
  }
  .mapboxgl-popup-tip {
    display: none;
  }
`;

const LocationMap = () => {
  const { assets } = useContext(AssetContext); // Use useContext to access data
  const navigate = useNavigate();
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const popupRef = useRef(new mapboxgl.Popup({ 
    closeButton: false, 
    closeOnClick: false,
    className: 'custom-popup',
    offset: 15
  }));
  const [mapLoaded, setMapLoaded] = useState(false);
  const [selectedPoint, setSelectedPoint] = useState(null);

  useEffect(() => {
    const styleElement = document.createElement('style');
    styleElement.textContent = customPopupStyle;
    document.head.appendChild(styleElement);

    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  useEffect(() => {
    if (assets.length > 0 && !mapRef.current) {
      mapboxgl.accessToken = MAPBOX_TOKEN;
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: 'mapbox://styles/mapbox/satellite-streets-v11',
        center: [assets[0].lon, assets[0].lat],
        zoom: 22,
        minZoom: 15,
        maxZoom: 22,
        attributionControl: false,
        dragRotate: false,
      });

      mapRef.current.on('load', () => {
        setMapLoaded(true);

        mapRef.current.loadImage(customMarkerIcon, (error, image) => {
          if (error) throw error;
          
          mapRef.current.addImage('custom-marker', image);

          if (!mapRef.current.getSource('points')) {
            mapRef.current.addSource('points', {
              type: 'geojson',
              data: {
                type: 'FeatureCollection',
                features: assets.map((asset) => ({
                  type: 'Feature',
                  geometry: {
                    type: 'Point',
                    coordinates: [asset.lon, asset.lat],
                  },
                  properties: {
                    asset_name: asset.asset_name,
                    asset_id: asset.asset_id,
                  },
                })),
              },
              cluster: true,
              clusterMaxZoom: 8,
              clusterRadius: 50,
            });

            mapRef.current.addLayer({
              id: 'unclustered-point',
              type: 'symbol',
              source: 'points',
              filter: ['!', ['has', 'point_count']],
              layout: {
                'icon-image': 'custom-marker',
                'text-field': ['get', 'asset_id'],
                'text-font': [
                    'Open Sans Semibold',
                    'Arial Unicode MS Bold'
                ],
                'text-offset': [0, 1.25],
                'text-anchor': 'top',
                'icon-size': 0.05,
              },
              paint: {
                'text-halo-color': '#FFFFFF',
                'text-halo-width': 2,
                'text-halo-blur': 0.5,
              },
            });

            // Event to handle click on marker
            mapRef.current.on('click', 'unclustered-point', (e) => {
              const { asset_id } = e.features[0].properties;
              // Redirect to AssetDetails with asset_id
              navigate(`/asset-details/${asset_id}`);
            });

            // Popup events
            mapRef.current.on('mouseenter', 'unclustered-point', (e) => {
              mapRef.current.getCanvas().style.cursor = 'pointer';
              const coordinates = e.features[0].geometry.coordinates.slice();
              const { asset_name, asset_id } = e.features[0].properties;

              while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
              }

              const popupContent = `
                <p style="margin: 0; color: #242424"><strong>ID:</strong> ${asset_id}</p>
                <p style="margin: 5px 0 0; color: #242424""><strong>Name:</strong> ${asset_name}</p>
              `;

              popupRef.current
                .setLngLat(coordinates)
                .setHTML(popupContent)
                .addTo(mapRef.current);
            });

            mapRef.current.on('mouseleave', 'unclustered-point', () => {
              mapRef.current.getCanvas().style.cursor = '';
              popupRef.current.remove();
            });
          }
        });
      });

      const bounds = assets.reduce((bounds, asset) => {
        return bounds.extend([asset.lon, asset.lat]);
      }, new mapboxgl.LngLatBounds([assets[0].lon, assets[0].lat], [assets[0].lon, assets[0].lat]));

      mapRef.current.fitBounds(bounds, {
        padding: 100,
        maxZoom: 22,
      });
    }
  }, [assets]);

  return (
    <div className='map-container'>
      <div className='landing-container'>
        <div className='first-group'>
          <h1>Hail Creek Mine</h1>
          <h6>Choose an asset to check the risk management details.</h6>
          <CustomDropdown points={assets} setSelectedPoint={setSelectedPoint} />
        </div>
        <div className='second-group'>
          <Link to="/digital-twin" className="link-button">
            <img src={digitalIcon} alt="User Icon" className="button-image"></img>
            <div>
              <h6>Digital Twin</h6>
              <span className="button-text">Digital Asset</span>
            </div>
          </Link>
          <Link to="/analytics" className="link-button">
            <img src={analyticsIcon} className="button-image"></img>
            <div>
              <h6>Analytics</h6>
              <span className="button-text">Data Insights</span>
            </div>
          </Link>
        </div>
      </div>
      <div ref={mapContainerRef} style={{ height: '89.8vh', width: '100%' }} />
    </div>
  );
};

export default LocationMap;
