import React from 'react';
import Modal from 'react-modal';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import * as pdfjs from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry.js';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';

import { GlobalWorkerOptions } from 'pdfjs-dist';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function PdfViewerModal({ isOpen, onClose, fileUrl }) {
    // We initialize the toolbar plugin
    const toolbarPluginInstance = toolbarPlugin();
    const { Toolbar } = toolbarPluginInstance;

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="PDF Viewer"
            style={{
                content: { width: '70%', height: '80%', margin: 'auto' }
            }}
        >
            <button
                onClick={onClose}
                style={{ 
                    borderRadius: '4px', 
                    padding: '4px 6px',
                    border: 'none',
                    fontFamily: 'Prometo Trial',
                    color: '#5c5c5c',
                    cursor: 'pointer',
                }}
            >
                Close
            </button>
            <div style={{ height: 'auto' }}>
                <Worker workerUrl={pdfjsWorker}>
                    {/* Custom toolbar*/}
                    <Toolbar>
                        {(props) => {
                            const {
                                CurrentPageInput,
                                GoToNextPage,
                                GoToPreviousPage,
                                Zoom,
                                Print,
                                Download,
                            } = props;

                            return (
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'end',
                                        marginBottom: '20px',
                                        marginRight: '20px',
                                    }}
                                >
                                    {/* Show only the necessary components */}
                                    <GoToPreviousPage />
                                    <CurrentPageInput />
                                    <GoToNextPage />
                                    <Zoom />
                                    <Print />
                                    <Download />
                                </div>
                            );
                        }}
                    </Toolbar>
                    {/* PDF Viewer*/}
                    <Viewer
                        fileUrl={fileUrl}
                        plugins={[toolbarPluginInstance]}
                        enableForms={true}
                        defaultScale={0.8}
                    />
                </Worker>
            </div>
        </Modal>
    );
}

export default PdfViewerModal;
