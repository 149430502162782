import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import axios from 'axios';

import CustomAlert from '../components/CustomAlert';

function GetAnAccount(){
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [country, setCountry] = useState('');
    const [alert, setAlert] = useState(null); 

    const getAnAccount = (e) => {
        e.preventDefault();
        axios.post("https://www.mincka-aim.com/api/get-account", {
            first_name: firstName,
            last_name: lastName,
            email: email,
            company: company,
            country: country
        })
        .then(function (response) {
            setAlert({ message: 'Your request was sent successfully!', type: 'success' });
            setFirstName('');
            setLastName('');
            setEmail('');
            setCompany('');
            setCountry('');
        })
        .catch(function (error) {
            console.log('Error:', error);
            if (error.response && error.response.status === 409) {
                setAlert({ message: 'Email already exists', type: 'error' });
            } else {
                setAlert({ message: 'An error occurred, please try again.', type: 'error' });
            }
        });
    };

    return (
        <div className="login-container App">
          {alert && <CustomAlert message={alert.message} type={alert.type} onClose={() => setAlert(null)} />}
          <div className="login-background">
            <div className="login-box">
              <h4>Get an Account</h4>
              <form onSubmit={getAnAccount}>
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Company"
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Country"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    required
                  />
                </div>
                <button type="submit" className="login-button">Get an Account</button>
              </form>
              <p className='have-an-account'>
                Already have an account?
                <Link to="/">Sign In</Link>
              </p>
            </div>
          </div>
        </div>
      );
}

export default GetAnAccount;
