import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import { FaChevronDown } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import control_icon from '../assets/icons/control_room_icon.png';
import workshop_icon from '../assets/icons/workshop_icon.png';
import main_office_icon from '../assets/icons/main_office_icon.png';
import warehouse_icon from '../assets/icons/warehouse_icon.png';
import office_icon from '../assets/icons/office_icon.png';
import engineering from '../assets/icons/engineering.png';
import laboratory from '../assets/icons/laboratory.png';
import administrative from '../assets/icons/administrative.png';
import coal_handling from '../assets/icons/coal_handling.png';

// Icon mapping based on asset_name or asset_id
const iconMapping = {
  'Control Room': control_icon,
  'Workshop': workshop_icon,
  'Main Office': main_office_icon,
  'Warehouse': warehouse_icon,
  'Office': office_icon,
  'Engineering Office': engineering,
  'Laboratory': laboratory,
  'Administration Building': administrative,
  'Coal handling and preparation plant': coal_handling,
};

const DropdownIndicator = (props) => {
  const { selectProps } = props;
  const rotate = selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)';

  return (
    <components.DropdownIndicator {...props}>
      <div style={{ transition: 'transform 0.2s', transform: rotate }}>
        <FaChevronDown size={20} color="#A1A1A1" />
      </div>
    </components.DropdownIndicator>
  );
};

const Option = (props) => {
  const { data, ...rest } = props;
  return (
    <components.Option {...rest}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {data.point.asset_id} - {data.label}
      </div>
    </components.Option>
  );
};

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    color: '#A1A1A1',
    width: '21.25rem',
    height: '3.25rem',
    minHeight: '3.25rem',
    borderRadius: '10px',
    padding: '0 10px',
    borderColor: '#ccc',
    boxShadow: 'none',
    PointerEvent: 'auto',
    fontFamily: 'Prometo Trial',
    fontSize: '16px',
    cursor: 'pointer',
    '&:hover': {
      borderColor: '#ccc',
    },
  }),
  menu: (provided) => ({
    ...provided,
    width: '21.25rem',
    borderRadius: '10px',
    overflow: 'hidden',
  }),
  menuList: (provided) => ({
    ...provided,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#f0f0f0' : 'white',
    color: state.isSelected ? '#000' : '#A1A1A1',
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: '#e0e0e0',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#A1A1A1',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#A1A1A1',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#A1A1A1',
    '&:hover': {
      color: '#A1A1A1',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
};

const CustomDropdown = ({ points, setSelectedPoint }) => {
  const [options, setOptions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Generates options dynamically based on the loaded points
    const dropdownOptions = points.map((point) => ({
      value: point.asset_id,
      label: point.asset_name,
      point,
      image: iconMapping[point.asset_name] || office_icon,
    }));
    setOptions(dropdownOptions);
  }, [points]);

  const handleChange = (selectedOption) => {
    setSelectedPoint(selectedOption.point);
    navigate(`/asset-details/${selectedOption.value}`);
  };

  return (
    <div>
      <Select
        id="location-select"
        className="select"
        onChange={handleChange}
        options={options}
        isSearchable={false}
        components={{ DropdownIndicator, Option }}
        placeholder="Select an asset..."
        styles={customStyles}
      />
    </div>
  );
};

export default CustomDropdown;
