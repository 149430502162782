import React, { useEffect, useState } from 'react';

const ImageGallery = ({ images, isOpen, selectedImageIndex, onClose }) => {
  const [currentIndex, setCurrentIndex] = useState(selectedImageIndex);
  const [imageData, setImageData] = useState([]);

  useEffect(() => {
    // Carga y analiza las dimensiones de todas las imágenes
    const loadImageDimensions = async () => {
      const loadedImages = await Promise.all(
        images.map((src) => {
          return new Promise((resolve) => {
            const img = new Image();
            img.onload = () => {
              resolve({
                src,
                width: img.width,
                height: img.height,
                aspectRatio: img.width / img.height
              });
            };
            img.src = src;
          });
        })
      );
      setImageData(loadedImages);
    };

    loadImageDimensions();
  }, [images]);

  const nextImage = () => {
    setCurrentIndex((prev) => (prev + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentIndex((prev) => (prev - 1 + images.length) % images.length);
  };

  if (!isOpen) return null;

  const currentImage = imageData[currentIndex];

  return (
    <div 
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1000
      }}
    >
      <div 
        style={{
          position: 'relative',
          width: '90%',
          height: '85%',
          maxWidth: '1200px',
          backgroundColor: '#fff',
          borderRadius: '8px',
          overflow: 'hidden'
        }}
      >
        {/* Botón de cierre */}
        <button
          onClick={onClose}
          style={{
            position: 'absolute',
            fontFamily: 'Prometo Trial',
            right: '16px',
            top: '16px',
            zIndex: 10,
            padding: '4px 6px',
            backgroundColor: '#F0F0F0',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            fontSize: '13px',
            color: '#5C5C5C'
          }}
        >
          Close
        </button>

        {/* Contenedor principal */}
        <div 
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            padding: '32px'
          }}
        >
          {/* Botón anterior */}
          <button
            onClick={prevImage}
            style={{
              position: 'absolute',
              left: '16px',
              padding: '16px',
              fontSize: '24px',
              color: 'white',
              backgroundColor: 'rgba(0, 0, 0, 0.3)',
              border: 'none',
              borderRadius: '9999px',
              cursor: 'pointer'
            }}
          >
            ‹
          </button>

          {/* Contenedor de imagen */}
          <div 
            style={{
              position: 'relative',
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            {currentImage && (
              <img
                src={currentImage.src}
                alt={`Image ${currentIndex + 1}`}
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  objectFit: 'contain',
                  width: currentImage.aspectRatio > 1 ? 'auto' : '80%',
                  height: currentImage.aspectRatio > 1 ? '80%' : 'auto'
                }}
              />
            )}
          </div>

          {/* Botón siguiente */}
          <button
            onClick={nextImage}
            style={{
              position: 'absolute',
              right: '16px',
              padding: '16px',
              fontSize: '24px',
              color: 'white',
              backgroundColor: 'rgba(0, 0, 0, 0.3)',
              border: 'none',
              borderRadius: '9999px',
              cursor: 'pointer'
            }}
          >
            ›
          </button>
        </div>

        {/* Indicador de posición */}
        <div 
          style={{
            position: 'absolute',
            bottom: '16px',
            left: '0',
            right: '0',
            display: 'flex',
            justifyContent: 'center',
            gap: '8px'
          }}
        >
          {images.map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentIndex(index)}
              style={{
                width: '8px',
                height: '8px',
                borderRadius: '9999px',
                backgroundColor: index === currentIndex ? '#3b82f6' : '#9ca3af',
                border: 'none',
                padding: 0,
                cursor: 'pointer'
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ImageGallery;