import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

function InConstruction() {
    return (
        <div className="construction-container">
                <div className="">
                    <h1>In Construction</h1>
                    <p style={{ color: '#000'}}>This component is still under development.</p>
                </div>
            <Link to="/landing" className="login-button align-button">Go Back</Link>
        </div>
    );
};

export default InConstruction;