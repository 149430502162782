import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import Background_grey from '../assets/icons/Background_grey.svg';
import Help_icon from '../assets/icons/help.svg';
import User_icon from '../assets/icons/User_Account_Gold.svg';
import Glencore_logo from '../assets/icons/Glencore_logo.png';

function Nav() {
    const [isLogOutOpen, setLogOutOpen] = useState(false);
    const navigate = useNavigate(); 
    const location = useLocation();

    const user = {
        name: 'Mincka',
        email: 'Mincka@gmail.com'
    };

    const toggleLogOut = () => {
        setLogOutOpen(prev => !prev);
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/');
        setLogOutOpen(false);
    };

    const goToHomePage = () => {
        navigate('/landing');
    };

    // Condition to hide User_icon in LoginPage and GetAnAccount
    const hideUserIcon = location.pathname === '/' || location.pathname === '/get-account';

    return (
        <nav className="Main-nav">
            <ul className="nav-items">
                <li className="nav-logo">
                    <img 
                    src={Background_grey} 
                    alt="Logo de Mincka" 
                    className='nav-img'
                    onClick={goToHomePage}
                     />
                    
                </li>
                <div className="nav-right">
                    <img src={Glencore_logo} alt="Glencore" className='icon-img-glencore' />
                    <img src={Help_icon} alt="Help Icon" className='icon-img' />
                    {!hideUserIcon && (
                        <img 
                            src={User_icon} 
                            alt="User Icon" 
                            className='icon-img' 
                            onClick={toggleLogOut} 
                            style={{ cursor: 'pointer' }}
                        />
                    )}
                    {isLogOutOpen && (
                        <div className="log-out">
                            <p><strong>Name:</strong> {user.name}</p>
                            <p><strong>Email:</strong> {user.email}</p>
                            <div>
                                <button onClick={handleLogout}>Log Out</button>
                            </div>
                        </div>
                    )}
                </div>
            </ul>
        </nav>
    );
};

export default Nav;
