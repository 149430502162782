import React, { createContext, useState, useEffect } from 'react';
import * as XLSX from 'xlsx';

export const AssetContext = createContext();

export const AssetProvider = ({ children }) => {
  const [assets, setAssets] = useState([]);
  const [defects, setDefects] = useState([]);

  // Load data from asset_list.xlsx
  useEffect(() => {
    const loadAssetData = async () => {
      try {
        const response = await fetch('/data/asset_list.xlsx');
        if (!response.ok) {
          throw new Error('Error loading Excel file');
        }
        const data = await response.arrayBuffer();
        const workbook = XLSX.read(data);
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);
        setAssets(jsonData);
      } catch (error) {
        console.error('Error loading or processing Excel file:', error);
      }
    };

    loadAssetData();
  }, []);

  // Load data from defect_list.xlsx
  useEffect(() => {
    const loadDefectData = async () => {
      try {
        const response = await fetch('/data/defect_list.xlsx');
        const data = await response.arrayBuffer();
        const workbook = XLSX.read(data);
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);
        setDefects(jsonData);
      } catch (error) {
        console.error('Error loading defect list:', error);
      }
    };

    loadDefectData();
  }, []);

  return (
    <AssetContext.Provider value={{ assets, defects }}>
      {children}
    </AssetContext.Provider>
  );
};
