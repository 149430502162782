import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'; // Importa Navigate
import { AssetProvider } from './contexts/AssetListContext'; // Import context provider

import './css/Values.css';
import './css/App.css';

import Nav from './components/Nav';
import CostAnalysis from './components/CostAnalysis';
import RemedialActions from './components/RemedialActions'
import Graphics from './components/Graphics'
import InConstruction from './components/InConstruction';
import PrivateRoute from './components/PrivateRoute';

import Analytics from './pages/Analytics';
import AssetDetails from './pages/AssetDetails';
import DefectDetails from './pages/DefectDetails';
import GetAnAccount from './pages/GetAnAccount';
import LocationMap from './pages/LocationMap';
import LoginPage from './pages/LoginPage';
import DigitalTwin from './components/DigitalTwin'

function App() {
  return (
    <div>
      <div className="App">
        <BrowserRouter>
          <Nav />
          <AssetProvider> 
            <Routes>
              <Route path="/" element={<LoginPage />} />
              <Route path="/get-account" element={<GetAnAccount />} />
              <Route 
                path="/landing" 
                element={
                  <PrivateRoute>
                    <LocationMap />
                  </PrivateRoute>
                } 
              />
              <Route 
                path="/in-construction" 
                element={
                  <PrivateRoute>
                    <InConstruction />
                  </PrivateRoute>
                } 
              />
              <Route 
                path="/digital-twin" 
                element={
                  <PrivateRoute>
                    <DigitalTwin/>
                  </PrivateRoute>
                } 
              />
              <Route 
                path="/analytics" 
                element={
                  <PrivateRoute>
                    <Analytics />
                  </PrivateRoute>
                } 
              />
              <Route 
                path="/cost-analysis" 
                element={
                  <PrivateRoute>
                    <CostAnalysis />
                  </PrivateRoute>
                } 
              />
              <Route 
                path="/remedial-actions" 
                element={
                  <PrivateRoute>
                    <RemedialActions />
                  </PrivateRoute>
                } 
              />
              <Route 
                path="/graphics" 
                element={
                  <PrivateRoute>
                    <Graphics />
                  </PrivateRoute>
                } 
              />
              <Route 
                path="/asset-details/:asset_id" 
                element={
                  <PrivateRoute>
                    <AssetDetails />
                  </PrivateRoute>
                } 
              />
              <Route 
                path="/defect-details/:defect_id" 
                element={
                  <PrivateRoute>
                    <DefectDetails />
                  </PrivateRoute>
                } 
              />
              {/* Catch-all route to redirect to /landing */}
              <Route path="*" element={<Navigate to="/landing" />} />
            </Routes>
          </AssetProvider>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
