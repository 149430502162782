import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { AssetContext } from '../contexts/AssetListContext.js';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts'; // Recharts functionalities
import backIcon from '../assets/icons/navigate_before.svg'
import graphics from '../assets/icons/graphics.svg'

const Graphics = () => {
    const { defects } = useContext(AssetContext);
    const navigate = useNavigate();
    const [colors, setColors] = useState({});
    const { state } = useLocation();
    const [taskCategoryData, setTaskCategoryData] = useState([]);
    const [actionsCategoryData, setActionsCategoryData] = useState([]);
    const [maxYValue, setMaxYValue] = useState(0);

    // Get CSS variable colors when component mounts
    useEffect(() => {
        const riskColors = {
            'Very High': getCssVariableValue('--risk-color-4'),
            'High': getCssVariableValue('--risk-color-3'),
            'Medium': getCssVariableValue('--risk-color-2'),
            'Low': getCssVariableValue('--risk-color-1'),
        };
        setColors(riskColors);
    }, []);

    // Function to get the value of a CSS variable
    const getCssVariableValue = (variableName) => {
        return getComputedStyle(document.documentElement).getPropertyValue(variableName).trim();
    };


    // Back button
    const goBack = () => {
        navigate('/analytics');
    };
    // useEffect to manage data in Number of Tasks per Type of Maintenance with Risk Levels
    useEffect(() => {
        if (defects && defects.length > 0) {
            // Count tasks by category and risk level
            const actionsCounts = defects.reduce((acc, defect) => {
                const category = defect['Task type']; // 'Corrective' or 'Preventive'
                const actionsCategory = defect['Task category']; // 'Replace', 'Monitor', etc.

                if (!acc[category]) {
                    acc[category] = {
                        name: category,
                        Safety: 0,
                        Housekeeping: 0,
                        Structural: 0,
                        total: 0
                    };
                }

                acc[category][actionsCategory]++;
                acc[category].total++;

                return acc;
            }, {});

            // Convert to array format for Recharts
            const chartActionData = Object.values(actionsCounts);

            // Calculate maximum total value
            const maxTotal = Math.max(...chartActionData.map(item => item.total));
            // Add 20% to the maximum value for padding
            setMaxYValue(Math.ceil(maxTotal * 1.2));

            setActionsCategoryData(chartActionData);
        }
    }, [defects]);

    // useEffect to manage data in Number of Tasks per Type of Maintenance with Risk Levels
    useEffect(() => {
        if (defects && defects.length > 0) {
            // Count tasks by category and risk level
            const categoryCounts = defects.reduce((acc, defect) => {
                const category = defect['Task type']; // 'Corrective' or 'Preventive'
                const riskLevel = defect.risk_rate; // 'Extreme', 'Major', etc.

                if (!acc[category]) {
                    acc[category] = {
                        name: category,
                        'Very High': 0,
                        'High': 0,
                        'Medium': 0,
                        'Low': 0,
                        total: 0
                    };
                }

                acc[category][riskLevel]++;
                acc[category].total++;

                return acc;
            }, {});

            // Convert to array format for Recharts
            const chartData = Object.values(categoryCounts);

            // Calculate maximum total value
            const maxTotal = Math.max(...chartData.map(item => item.total));
            // Add 20% to the maximum value for padding
            setMaxYValue(Math.ceil(maxTotal * 1.2));

            setTaskCategoryData(chartData);
        }
    }, [defects]);

    return (
        <div className='cost-analysis-container'>
            <div className='cost-analytics-title'>
                <div>
                    <h2>Analytics</h2>
                    <Link to="/cost-analysis"
                        className='analysis-button'
                    >
                        Cost Analysis
                    </Link>
                    <Link to="/remedial-actions"
                        className='analysis-button-active'
                    >
                        Remedial Actions
                    </Link>
                </div>
                <div style={{ display: 'flex', alignContent: 'center' }}>
                    <Link
                        className='graphics-button'
                        style={{ backgroundColor: '#D8C041' }}
                    >
                        <img src={graphics} className="button-image"></img>
                        <div style={{ display: 'block' }}>
                            <h6 style={{ fontSize: '16px', color: '#000' }}>Action Tasks</h6>
                        </div>
                    </Link>
                    <button
                        className='back-button'
                        style={{ width: '100px' }}
                        onClick={goBack}
                    >
                        <img src={backIcon} alt="Back" />
                        Analytics
                    </button>
                </div>
            </div>


            {/* New Graphics View */}
            <div className="grid-container" style={{ height: '80vh' }}>
                <div className='grid-item'>
                    <h3>Number of actions</h3>
                    <BarChart
                        width={500}
                        height={300}
                        data={actionsCategoryData}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis domain={[0, maxYValue]} />
                        <Tooltip
                            contentStyle={{
                                borderRadius: '4px',
                                fontSize: '14px',
                                padding: '10px'
                            }}
                            itemStyle={{ color: '#000' }}
                        />
                        <Legend />
                        <Bar dataKey="Housekeeping" stackId="a" fill="#8884d8" barSize={80} />
                        <Bar dataKey="Safety" stackId="a" fill="#82ca9d" barSize={80} />
                        <Bar dataKey="Structural" stackId="a" fill="#AAFFFF" barSize={80} />
                    </BarChart>

                </div>
                <div className='grid-item'>
                    <h3>Number of Tasks per Type of Maintenance with Risk Levels</h3>
                    <BarChart
                        width={500}
                        height={300}
                        data={taskCategoryData}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis domain={[0, maxYValue]} />
                        <Tooltip
                            contentStyle={{
                                borderRadius: '4px',
                                fontSize: '14px',
                                padding: '10px'
                            }}
                            itemStyle={{ color: '#000' }}
                        />
                        <Legend />
                        <Bar dataKey="Very High" stackId="a" fill={colors['Very High']} barSize={80} />
                        <Bar dataKey="High" stackId="a" fill={colors['High']} barSize={80} />
                        <Bar dataKey="Medium" stackId="a" fill={colors['Medium']} barSize={80} />
                        <Bar dataKey="Low" stackId="a" fill={colors['Low']} barSize={80} />
                    </BarChart>
                </div>
            </div>
        </div>
    )
}

export default Graphics;