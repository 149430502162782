import React from 'react';

// Define the risk levels and their properties
const RISK_LEVELS = {
    'Very High': {
        color: '#FF0000', // Red
        label: 'Very High'
    },
    'High': {
        color: '#FFC000', // Yellow
        label: 'High'
    },
    'Medium': {
        color: '#FFFF00', // Blue
        label: 'Medium'
    },
    'Low': {
        color: '#00B050', // Green
        label: 'Low'
    }
};

const RiskIcon = ({
    riskLevel,
    size = 24,
    showLabel = false,
    className = '',
    onClick = null
}) => {
    const riskConfig = RISK_LEVELS[riskLevel] || RISK_LEVELS.Low;

    return (
        <div
            className={`flex items-center gap-2 ${className}`}
            onClick={onClick}
            style={{ cursor: onClick ? 'pointer' : 'default' }}
        >
            <svg
                width={size}
                height={(size * 32) / 30} // Adjust the height to maintain the ratio of the original SVG
                viewBox="0 0 30 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g filter="url(#filter0_d)">
                    <path
                        d="M26 10.2609C26 15.9278 21.8983 20 15 24C8.1017 19.4783 4 15.9278 4 10.2609C4 4.59395 8.92487 0 15 0C21.0751 0 26 4.59395 26 10.2609Z"
                        fill={riskConfig.color}
                    />
                    <path
                        d="M15.1864 15.9129C14.8275 15.9129 14.5203 15.7937 14.2647 15.5553C14.0091 15.3169 13.8813 15.0303 13.8813 14.6956C13.8813 14.3608 14.0091 14.0742 14.2647 13.8358C14.5203 13.5974 14.8275 13.4782 15.1864 13.4782C15.5453 13.4782 15.8526 13.5974 16.1081 13.8358C16.3637 14.0742 16.4915 14.3608 16.4915 14.6956C16.4915 15.0303 16.3637 15.3169 16.1081 15.5553C15.8526 15.7937 15.5453 15.9129 15.1864 15.9129ZM13.8813 12.2608V4.95642H16.4915V12.2608H13.8813Z"
                        fill="white"
                    />
                </g>
                <defs>
                    <filter id="filter0_d" x="0" y="0" width="30" height="32" filterUnits="userSpaceOnUse">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                        <feOffset dy="4" />
                        <feGaussianBlur stdDeviation="2" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                    </filter>
                </defs>
            </svg>
            {showLabel && (
                <span className="text-sm font-medium">{riskConfig.label}</span>
            )}
        </div>
    );
};

// Component to display all risk levels
const RiskRateSection = () => {
    return (
        <div className="risk-rate">
            <p>Risk Rate</p>
            <div className="risk-rate-img">
                {Object.keys(RISK_LEVELS).map((level) => (
                    <RiskIcon
                        key={level}
                        riskLevel={level}
                        size={24}
                        showLabel={true}
                    />
                ))}
            </div>
        </div>
    );
};

export { RiskIcon, RiskRateSection, RISK_LEVELS };
