import React, { useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { AssetContext } from '../contexts/AssetListContext.js';

import PdfViewerModal from '../components/PdfViewerModal.js';
import ImageGallery from '../components/ImageGallery';

import detailedPDF from '../assets/icons/detailed_pdf_icon.svg';
import assetLocation from '../assets/icons/asset_location_icon.svg';
import photoIcon from '../assets/icons/inspection_photo_icon.svg';
import backIcon from '../assets/icons/navigate_before.svg'

import { RiskIcon, RiskRateSection } from '../components/RiskRateSection.js';

function DefectDetails() {
    const { defect_id } = useParams(); // Get defect_id from URL params
    const {defects } = useContext(AssetContext);

    const navigate = useNavigate();

    // Image Gallery state
    const [isGalleryOpen, setIsGalleryOpen] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);

    const openGallery = (index) => {
        setSelectedImageIndex(index);
        setIsGalleryOpen(true);
    };

    const closeGallery = () => {
        setIsGalleryOpen(false);
    };

    // PDF Viewer states
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    // Filtering the defect using defect_id
    const defect = defects.find(def => def.defect_id === defect_id);

    if (!defect) {
        return <p>No defect found for the given defect_id</p>;
    }

    // Mapping photos into gallery
    const images = [
        `https://drive.google.com/thumbnail?id=${defect.photo_1}&sz=s4000`,
        `https://drive.google.com/thumbnail?id=${defect.photo_2}&sz=s4000`,
        `https://drive.google.com/thumbnail?id=${defect.photo_3}&sz=s4000`
    ];

    // Back button
    const goBack = () => {
        navigate(`/asset-details/${defect.asset_id}`);
    };

    return (
        <div className='defect-details-container'>
            <div className='defect-details-title'>
            <h2>{defect_id}</h2>
            <button 
            className='back-button'
            onClick={goBack}
            style={{width: '160px',}}
            >
                <img src={backIcon} />
                Back to Asset
                 
            </button>
            </div>
            
            <div className='defect-details-section'>
                <div className='defect-details-main-section'>
                    <img src={`https://drive.google.com/thumbnail?id=${defect.photo_0}&sz=s4000`} alt="Asset Photo" />
                </div>
                <div className='defect-details-side-section'>
                    <div className='report-section'>
                        <div>
                            <img
                                src={detailedPDF}
                                alt="Alert icon"
                                style={{ width: '40px', height: '40px' }}
                            ></img>
                            <div className='report-section-title'>
                                <h5>Report</h5>
                                <p>Detailed PDF</p>
                            </div>
                        </div>
                        <button onClick={openModal}>
                            View
                        </button>
                    </div>
                    <div className='asset-location-section'>
                        <div className='asset-location-section-one'>
                            <img
                                src={assetLocation}
                                alt="Alert icon"
                                style={{ width: '40px', height: '40px' }}
                            ></img>
                            <h5 style={{ color: '#333333', }}>Asset Location</h5>
                        </div>
                        <div className='asset-location-section-two'>
                            <h6 style={{ color: '#333333', fontSize: '16px', }}>Asset:</h6>
                            <p style={{ color: '#767676', }}>{defect.asset_id} - {defect.asset_name}</p>
                        </div>
                        <div className='asset-location-section-two'>
                            <h6 style={{ color: '#333333', fontSize: '16px', }}>Area:</h6>
                            <p style={{ color: '#767676', paddingLeft: '10px', }}>{defect.area}</p>
                        </div>
                    </div>

                    <div className='defect-risk-rate'>
                    <RiskIcon
                      riskLevel={defect.risk_rate}
                      size={30}
                    />

                        <div>
                            <h6 style={{ color: '#333333', }}>{defect.defect_type}</h6>
                            <p style={{ color: '#757575', }}>{defect.risk_rate} Risk</p>
                        </div>
                    </div>
                    <div className='inspection-photos'>
                        <div className='inspection-photos-title'>
                            <img
                                src={photoIcon}
                            ></img>
                            <h5 style={{ color: '#333333', }}>Inspection Photos</h5>
                        </div>
                        <div className='inspection-photos-selection'>
                            <div>
                                <img
                                    src={images[0]}
                                    alt="Defect Photo 1"
                                    onClick={() => openGallery(0)}
                                    style={{ cursor: 'pointer' }}
                                />
                            </div>
                            <div>
                                <img
                                    src={images[1]}
                                    alt="Defect Photo 2"
                                    onClick={() => openGallery(1)}
                                    style={{ cursor: 'pointer' }}
                                />
                            </div>
                            <div>
                                <img
                                    src={images[2]}
                                    alt="Defect Photo 3"
                                    onClick={() => openGallery(2)}
                                    style={{ cursor: 'pointer' }}
                                />
                            </div>
                        </div>
                        {isGalleryOpen && (
                            <ImageGallery
                                images={images}
                                isOpen={isGalleryOpen}
                                selectedImageIndex={selectedImageIndex}
                                onClose={closeGallery}
                            />
                        )}
                    </div>
                    {/* Modal with PDF viewer */}
                    <PdfViewerModal
                        isOpen={isModalOpen}
                        onClose={closeModal}
                        fileUrl={`/defect_reports/${defect.pdf_ref}`}
                    />
                </div>
            </div>

        </div>
    );
}

export default DefectDetails;